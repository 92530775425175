<template>
    <div class="radbar">
        <h3 @click="popup()">Rads</h3>
    <div class="radbackdrop">
        <div class="rad" :style="style"></div>
        <div class='spacer' style=''></div>
        <div class='spacer' style='left:20%'></div>
        <div class='spacer' style='left:40%'></div>
        <div class='spacer' style='left:60%'></div>

    </div>
        <h3 class="popup" @click="popup()">{{ rads }} / 1000</h3>
  </div>
  <div :id='popupid' class='modal'>
    <div class="modal-content">
    <span class="close" @click="closePopup()">&times;</span>
        <div class='radbuttons'>
            <button type="button" style="display:inline" @click='rads = rads-5'>-5</button>
            <button type="button" style="display:inline" @click='rads = rads-1'>-1</button>
            <input type="number" :value='rads' :id='idname'>
            <button type="button" style="display:inline" @click='rads = rads+1'>+1</button>
            <button type="button" style="display:inline" @click='rads = rads+5'>+5</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'Radbar',
    props: {
        rads:Number,
        name:String,
    },
    computed: {
        style() {
            return 'width: ' + this.rads/10 + '%;';
        },
        popupid() {
            return this.name + 'radpopupbox'
        },
        idname() {
            return this.name+'radinput'
        }
    },
    methods: {
        popup() {
            let modal = document.getElementById(this.name + 'radpopupbox')
            modal.style.display = 'block';
        },
        closePopup() {
            let modal = document.getElementById(this.name + 'radpopupbox')
            modal.style.display = 'none'
            let rad = document.getElementById(this.idname).value
            if (rad > 1000) { rad = 1000 }
            console.log(`Emitting a Rad change from Radbar with ${this.name} and ${rad}`);  
            this.$emit('modify-rads', this.name, rad )
        },
    },
    emits:['modify-rads'],
    

}
</script>

<style scoped>
div {
    height:14px;
    border-radius: 3px;
}
.radbackdrop {
    background-color:white;
    align-self: center;
}
.rad {
    background-color: green;
}
.radbar {
    width:95%;
    margin-left: 3px;
    display:grid;
    grid-template-columns: 15% 55% 30%;
    height:auto;
}
.spacer {
   width:20%;
   border-right: 2px solid black;
   border-radius: unset; 
   position: relative;
   margin-top: -14px;

}
h3 {
    text-align: center;
    width: auto;
    align-self: start;
}
.radbuttons {
    grid-template-columns: 15% 15% 15% 15% 15%;
    height: 15px;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  margin-bottom:20px;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>