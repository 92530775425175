<template>
  <p>{{line1}} <br> {{line2}}</p>
</template>

<script>
export default {
    name:'Descbox',
    props: {
        line1:String,
        line2:String,
    },

}
</script>

<style>

</style>