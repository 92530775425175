<template>
    <p @click='showpopup = !showpopup' class='popup'>{{text}}
    <span class="popuptext" v-if='showpopup==true && popuptextobject != undefined' :style='style'>
        <div :key='key' v-for='(piece, key) in popuptextobject'>
            <p v-if='piece != 0' style='font-weight:bold;display:inline;'>{{key.charAt(0).toUpperCase() + key.slice(1)}}: </p><p v-if='piece != 0' style='display:inline;'> {{piece}}</p>
        </div>
    </span> 
    <span class="popuptext" v-if='showpopup==true && popuptext != undefined'>
        <div><p>{{popuptext}}</p></div>
    </span> 
  </p>
</template>

<script>
export default {
    name:'Popup',
    props: {
        text:String,
        popuptext:String,
        popuptextobject:Object,
        textcolor:String,
        vert:Number,
    },
    data() {
        return{
            showpopup:false,
        }
    },
    computed: {
        style() {
            if (this.vert != 0){
                console.log(`margin-bottom:${this.vert}px;`)
            return `margin-bottom:${this.vert}px;`
            }
            else return ''
        },
    },
}
</script>

<style>
/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  
}

/* The actual popup (appears on top) */
.popup .popuptext {

  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  font-weight: normal;
  font-size: 18px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
</style>