import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {

    apiKey: "AIzaSyDmB4DwndgaswScmiWWdrusydJlnMZo1eU",
    authDomain: "colinsapp-e06ac.firebaseapp.com",
    projectId: "colinsapp-e06ac",
    storageBucket: "colinsapp-e06ac.appspot.com",
    messagingSenderId: "1030080378907",
    appId: "1:1030080378907:web:33ee9698e3f3bbfef7e0be"
  };
  
  const app = initializeApp(firebaseConfig);
  export const db = getFirestore(app);