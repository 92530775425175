<template>
  <input type='checkbox' v-model='char.checked' @change="$emit('toggle-checked')"/> 
  <p style='display:inline;'>{{label}}</p>
</template>

<script>
export default {
    name:'Checkbox',
        //CHAR = COMPLETE TRAIT/PERK OBJECT FROM CHAR DB
    props:{
        label:String,
        char:Object,
    },
    emits: ['toggle-checked',],
    created() {
        console.log(this.char.checked);
    },
    methods: {
        togglechecked() {
            
        },
    },
}
</script>

<style scoped>
h4 {
    display:inline;
}
</style>