<template>
    <div class="charsheet" >
        <h2>{{ charData.name }} - Level {{ charData.level }} <Popup :text='charData.race' :vert='vert' :popuptextobject='racePopupText' style='text-decoration:underline;' /></h2>
        <Hpbar :endurance='charData.special.endurance' :missinghp="charData.missingHp" :maxhp="charData.maxHp" :name="charData.name" @modify-health="modifyHealth" />
        <Radbar :rads="charData.rads" :name="charData.name" @modify-rads="modifyRads"/>
        <div class='divider'></div>
        <h3 style='margin:3px;'>S.P.E.C.I.A.L</h3>
        <div class='specialblock'>
            <Special stat="Strength"        :special="charData.special.strength"        :name="charData.name"/>
            <Special stat="Intelligence"    :special="charData.special.intelligence"    :name="charData.name"/>
            <Special stat="Perception"      :special="charData.special.perception"      :name="charData.name"/>
            <Special stat="Agility"         :special="charData.special.agility"         :name="charData.name"/>
            <Special stat="Endurance"       :special="charData.special.endurance"       :name="charData.name"/>
            <Special stat="Luck"            :special="charData.special.luck"            :name="charData.name"/>
            <Special stat="Charisma"        :special="charData.special.charisma"        :name="charData.name"/>
        </div>
        <div class='divider'></div>
        <h3>Skills</h3>
        <div class='specialblock'>
            <Skill stat="Barter"      :skill="charData.skills.barter"     :name="charData.name" :special="charData.special.charisma"    :luck="charData.special.luck"  specialname='charisma'    />
            <Skill stat="Energy Weps" :skill="charData.skills.energy"     :name="charData.name" :special="charData.special.perception"  :luck="charData.special.luck"  specialname='perception'  />
            <Skill stat="Explosives"  :skill="charData.skills.explosives" :name="charData.name" :special="charData.special.perception"  :luck="charData.special.luck"  specialname='perception'  />
            <Skill stat="Guns"        :skill="charData.skills.guns"       :name="charData.name" :special="charData.special.agility"     :luck="charData.special.luck"  specialname='agility'     />
            <Skill stat="Lockpick"    :skill="charData.skills.lockpick"   :name="charData.name" :special="charData.special.perception"  :luck="charData.special.luck"  specialname='perception'  />
            <Skill stat="Medicine"    :skill="charData.skills.medicine"   :name="charData.name" :special="charData.special.intelligence" :luck="charData.special.luck" specialname='intelligence' />
            <Skill stat="Melee Weps"  :skill="charData.skills.melee"      :name="charData.name" :special="charData.special.strength"    :luck="charData.special.luck"  specialname='strength'    />
            <Skill stat="Repair"      :skill="charData.skills.repair"     :name="charData.name" :special="charData.special.intelligence" :luck="charData.special.luck" specialname='intelligence' />
            <Skill stat="Science"     :skill="charData.skills.science"    :name="charData.name" :special="charData.special.intelligence" :luck="charData.special.luck" specialname='intelligence' />
            <Skill stat="Sneak"       :skill="charData.skills.sneak"      :name="charData.name" :special="charData.special.agility"     :luck="charData.special.luck"  specialname='agility'     />
            <Skill stat="Speech"      :skill="charData.skills.speech"     :name="charData.name" :special="charData.special.charisma"    :luck="charData.special.luck"  specialname='charisma'    />
            <Skill stat="Survival"    :skill="charData.skills.survival"   :name="charData.name" :special="charData.special.endurance"    :luck="charData.special.luck" specialname='endurance'   />
            <Skill stat="Unarmed"     :skill="charData.skills.unarmed"    :name="charData.name" :special="charData.special.strength"    :luck="charData.special.luck"  specialname='strength'    />
        </div>
        <div v-if='charData.traits != undefined'>
        <div class='divider'></div>
            <h3 style='margin-bottom:0px;'>Traits</h3>
            <div class='traitdiv'>
                <div :key='trait.id' v-for='trait in traits'>
                    <Traits :obj='trait' :name='charData.name'/>
                </div>
            </div>
        </div>
        <div v-if='charData.perks != undefined'>
            <div class='divider'></div>
            <h3 style='margin-bottom:0px;'>Perks</h3>
            <div class='traitdiv'>
                <div :key='perk.id' v-for='perk in perks'>
                    <Perks :obj='perk' :name='charData.name'/>
                </div>
            </div>
        </div>
        <div class='divider'></div>
        <h3 style='margin-bottom:0px;'>Weapon</h3>
        <Weapons :wep='weps' v-if='weps.id != undefined' :mods='charData.weaponstats' :special='charData.special' :skills='moddedskills'/>
        <div v-if='charData.offhand != undefined'>
            <h2>Offhand</h2>
        </div>
    </div>
</template>

<script>
function addobject(obj) {
    return Object.values(obj).reduce((a,b) => a+b)
};
export default {
    name: "CharacterSheet",
    props: {
        charData: Object,
    },
    components: {
        Hpbar,
        Radbar,
        Special,
        Skill,
        Traits,
        Perks,
        Weapons,
        Popup,
    },
    methods: {
        modifyHealth(name, missinghp) {
            console.log(`Received emit with ${name} and ${missinghp}`)
            this.$emit('modify-health', name, missinghp);
        },
        modifyRads(name, rads) {
            console.log(`Received emit with ${name} and ${rads}`)
            this.$emit('modify-rads', name, rads);
        },
        modifyStats(stat, value) {},
    },
    emits: ['modify-health', 'modify-rads'],
    computed: {
        traits() {
            let zarr = []
            for(let zobj in this.charData.traits) {
                zarr.push(this.charData.traits[zobj])
            }
            zarr.sort((a,b) => a.id > b.id)
            return zarr
        },
        perks() {
            let zarr = []
            for(let zobj in this.charData.perks) {
                zarr.push(this.charData.perks[zobj])
            }
            zarr.sort((a,b) => a.id > b.id)
            return zarr
        },
        racePopupText() {
           if (this.charData.race === 'Human') {
                return {'Action Surge':'Take another action once per rest', 'Second Wind':'Heal 1d10+level once per rest'}
           } 
           if (this.charData.race === 'Ghoul') {
                return {'Ugly and Smelly':'-1 to Charisma','Lucky AF':"Reroll Nat 1's",'Radical!':'Heal from Radiation' }
           } 
           if (this.charData.race === 'Super Mutant') {
                return {
                    'Not Radical?':'Immune to negative effects of radiation',
                    'Stat Stick':'Maximum SPECIAL cap is 12',
                    'Wolverine':`Heal ${this.charData.level} HP per turn`,
                    'Hulk Smash!':'Too big to wear armor',
                    'Straight Edge':'Drugs do not affect you',
                    'Head Hurty':'-1 to intelligence',
                    'Thick Skin':'AC is based on AGI and END',
                    'Rage':'Take half dmg and do +2 melee/unarmed dmg for 1 minute',
                    'Adamantium Bones':'Harder to cripple limbs'
                    }
           } 
        },
        vert() {
             if (this.charData.race === 'Super Mutant') {return -300} else {return 0}
        },
        moddedskills() {
            let moddedskills = JSON.parse(JSON.stringify(this.charData.skills))
            let skills =    ['barter', 'energy','explosives','guns','lockpick','medicine','melee','repair','science','sneak','speech','survival','unarmed']
            let special =   ['charisma','perception','perception','agility','perception','intelligence','strength','intelligence','intelligence','agility','charisma','endurance','strength']
            for (let i=0; i<skills.length;i++) {
                moddedskills[skills[i]][special[i].charAt(0).toUpperCase() + special[i].slice(1)] = addobject(this.charData.special[special[i]])*2
                //moddedskills.barter    .Charisma                                                = charisma total
                moddedskills[skills[i]].Luck = Math.ceil(addobject(this.charData.special.luck)/2)
                moddedskills[skills[i]].Constant = 2
            }
            console.log(moddedskills)
            return moddedskills
        },
    },
    async created() {
        let wepdata = onSnapshot(doc(db, 'weapons', this.charData.equippedweapon.id), (wepdoc) => {
            this.weps = wepdoc.data()
        })

    },
    async beforeUpdate() {

    },
    data() {
        return{
            weps:{},
        }
    },
}
import Hpbar from './Hpbar.vue'
import Radbar from './Radbar.vue'
import Special from './Special.vue'
import Skill from './Skill.vue'
import Traits from './Traits.vue'
import Perks from './Perks.vue'
import Weapons from './Weapons.vue'
import Popup from './Popup.vue'
import { doc, getDoc, setDoc , onSnapshot } from 'firebase/firestore'
import { db } from '@/db'
import { onBeforeUpdate } from '@vue/runtime-core';
</script>

<style scoped>
.charsheet {
    width: clamp(200px, 99%, 1000px);
    background-color: slategray;
    border: 3px solid black;
    border-radius: 15px;
    padding: 10px 0;
    margin: 15px auto;

}
.divider {
    border-bottom: 3px solid black;
    width: 80%;
    margin:auto;
    border-radius: 10px;
    margin-bottom:8px;
}
.specialblock {
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom:10px;
}
.traitdiv {
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom:0px;
}
</style>