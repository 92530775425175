<template>
  <div class="hpbar">
    <h3 @click="popup()">HP</h3>
    <div class="barbackdrop">
        <div class="bar" :style="style">
        </div>
    </div>
        <h3 class="popup" @click="popup()">{{ currenthp }} / {{ maxhp+mod }}</h3>
  </div>
  <div :id='popupid' class='modal'>
    <div class="modal-content">
    <span class="close" @click="closePopup()">&times;</span>
        <div class='hpbuttons'>
            <button type="button" style="display:inline" @click='missinghp = missinghp+5'>-5</button>
            <button type="button" style="display:inline" @click='missinghp = missinghp+1'>-1</button>
            <input type="number" :value='currenthp' :id='idname'>
            <button type="button" style="display:inline" @click='missinghp = missinghp-1'>+1</button>
            <button type="button" style="display:inline" @click='missinghp = missinghp-5'>+5</button>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    name:'Hpbar',
    data() {
        return {
            livehp:0,
        }
    },
    components: {

    },
    props: {
        missinghp: Number,
        maxhp: Number,
        name: String,
        endurance: Object,
    },
    computed: {
        style() {
            return 'width: ' + this.currenthp/(this.maxhp+this.mod) * 100 + '%;';
        },
        popupid() {
            return this.name + 'popupbox'
        },
        idname() {
            return this.name+'input'
        },
        currenthp() {return this.maxhp+this.mod-this.missinghp},
    },
    data() {
        return{
            percent:50,
            mod:Object.values(this.endurance).reduce((a,b) => a+b)-5,
            //currenthp: 0 
        }
    },
    methods: {
        popup() {
            let modal = document.getElementById(this.name + 'popupbox')
            modal.style.display = 'block';
        },
        closePopup() {
            let modal = document.getElementById(this.name + 'popupbox')
            modal.style.display = 'none'
            let missinghp = this.missinghp
            if (missinghp < 0) {missinghp = 0}
            if (missinghp > (this.maxhp+this.mod)) {missinghp = this.maxhp+this.mod}
            console.log(`Changing missing hp for ${this.name} to ${this.missinghp}`);
            this.$emit('modify-health', this.name, missinghp)
        },

    },
    created() {
        this.percent = this.currenthp/this.maxhp * 100
        this.livehp = this.currenthp

    },
    beforeUpdate() {
        this.mod = Object.values(this.endurance).reduce((a,b) => a+b)-5
        console.log('testing')
    },
    emits: ['modify-health'],
}

</script>

<style scoped>
div {
    height:14px;
    border-radius: 15px;
}
.barbackdrop {
    background-color:white;
    align-self: center;
}
.bar {
    background-color: red;
}
.hpbar {
    width:95%;
    margin-left: 3px;
    display:grid;
    grid-template-columns: 15% 55% 30%;
    height:auto;
}
h3 {
    text-align: center;
    width: auto;
    align-self: start;
}
.hpbuttons {
    grid-template-columns: 15% 15% 15% 15% 15%;
    height: 15px;
}


.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  margin-bottom:20px;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>