<template>
  <h4 class='main'><Popup style='display:inline;' :text='text' :popuptextobject='this.special' />
  <h4  v-if='(val-special.base>0)' style='display:inline'>(<h4 class='increased'>+</h4>)
  </h4>
  <h4  v-if='(val-special.base<0)' style='display:inline'>(<h4 class='decreased'>-</h4>)
  </h4>
  </h4>

</template>

<script>
function addobject(obj) {
    return Object.values(obj).reduce((a,b) => a+b)
};
export default {
    name:'Special',
    props: {
        stat:String,
        special:Object,
        name:String,

    },
    computed: {
      val() {
        return addobject(this.special)
      },
      text() {
        return `${this.stat}: ${this.val}`
      },
    },
    data() {return {
        raised:false,
        lowered:false,
    }},
    methods:{
        popup() {

        },
    },
    emits:[],
    created() {},
    beforeUpdate() {},
    components: {Popup}
}

import Popup from '@/components/Popup.vue'
</script>

<style scoped>
h4 {
    margin:auto;
}
.main{
    width:100%;
}
.increased {
    display:inline;
    color: lightgreen;
}
.decreased {
    display:inline;
    color:red;
}



/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.popup .popuptext {

  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -150px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}


</style>
