<template>
  <h4>{{wep.name}}
  <Popup style='display:inline;color:maroon;' v-if='disadvantage === true' text='!!DISADVANTAGE!!' :popuptext='disadvantagetext'/></h4>
  <!-- Damage Section -->
  <Popup :text='damage' :popuptextobject='damagevalues' />
  <h3 style='display:inline;'> | </h3>
  <Popup :text='hitchance' :popuptextobject='hitchancevalues' />
<h3 style='display:inline;'> | </h3>
  <p style='display:inline;'>Range: {{calcrange}}</p>
</template>

<script>
function addobject(obj) {
    return Object.values(obj).reduce((a,b) => a+b)
};
export default {
    name:'Weapons',
    props: {
        wep:Object,
        mods:Object,
        special:Object,
        skills:Object,
    },
    components: { Popup },
    created() {},
    data () {
        return{
            showpopupdmg:false,
            showpopuphit:false,
            disadvantagetext:'',
        }
    },
    beforeUpdate() {},
    computed: {
        calcrange() {
            let rng = ''
            if (this.wep.range === 'thrown') {
                    rng = (Object.values(this.special.strength).reduce((a,b) => a+b)*10) + ' ft'
                } else {
                    rng = this.wep.range
                }
            if (this.wep.radius != undefined) {rng += ' range, ' + this.wep.radius + ' radius'}
            return rng
        },
        damagevalues() {
            this.mods[this.wep.weaponclass].damage[this.wep.skillReq] = Math.floor(Object.values(this.skills[this.wep.weaponclass]).reduce((a,b) => a+b)/20)
            return this.mods[this.wep.weaponclass].damage
        },
        damage() {
            let dmg = 0
            let dmgstring = ''
            let values = Object.values(this.damagevalues)
            for(let i=0;i<values.length;i++) {
                if(typeof values[i] === 'number'){
                    dmg += values[i]
                } else if (typeof values[i] === 'string') {
                    dmgstring += `+ ${values[i]}`
                }
            }
            return `Damage: ${this.wep.damage} ${dmgstring} + ${dmg}`
        },
        testdmgtext() {
            return `Damage: ${this.dmgtest}`
        },
        hitchancevalues() {
            this.mods[this.wep.weaponclass].hit[this.wep.specialReq] = Object.values(this.special[this.wep.specialReq]).reduce((a,b) => a+b)-5
            return this.mods[this.wep.weaponclass].hit
        },
        hitchance() {
            return `Hit: ${Object.values(this.hitchancevalues).reduce((a,b) => a+b)}`
        },
        disadvantage() {
            if(addobject(this.skills[this.wep.weaponclass]) < this.wep.skillReqPoints) {
                this.disadvantagetext = `${this.wep.skillReq} is too low! ${addobject(this.skills[this.wep.weaponclass])}/${this.wep.skillReqPoints}`
                return true;
            }
            if(addobject(this.special.strength) < this.wep.strengthReq){return true}
        },

    },
}

import Popup from '@/components/Popup.vue'
</script>

<style scoped>




/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.popup .popuptext {

  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

</style>