<template>
  <div>
    <h4><Popup :text='perk.name' :popuptext="perk.popuptext"/></h4>
    <Checkbox v-if='perk.checkbox === true' :label='perk.label' :char='obj' @toggle-checked="toggleChecked" />
    <Descbox v-if='perk.descbox === true' :line1='perk.line1' :line2='perk.line2' />
  </div>
</template>

<script>
export default {
    name:'Perks',
    //OBJ = COMPLETE PERK OBJECT FROM CHAR DB
    props: {
        obj:Object,
        name:String,
    },
    data() {
        return{
            perk: {},
        }
    },
    components: {Checkbox, Descbox, Popup},
    async created() {
        let docdata = await getDoc(doc(db, 'perks', this.obj.id))
        console.log('printing data')
        console.log(docdata.data())
        this.perk = docdata.data()

    },
    methods: {
        toggleChecked() {
            console.log('Toggle triggered')
            console.log(this.perk)
            if (this.obj.checked === true){
                setDoc(doc(db, 'colinDB', this.name), this.perk.checked, {merge:true})
            } else {
                setDoc(doc(db, 'colinDB', this.name), this.perk.unchecked, {merge:true})
            }
        },
    },
}
import Checkbox from '@/components/Checkbox.vue'
import Descbox from '@/components/Descbox.vue'
import Popup from '@/components/Popup.vue'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '@/db'
</script>

<style>

</style>