<template>
  <h4 class='main'><Popup style='display:inline;' :text='text' :popuptextobject='valuesources' />
  <h4 v-if='zincreased' style='display:inline'>(<h4 class='increased'>+</h4>)
  </h4>
  <h4 v-if='zdecreased' style='display:inline'>(<h4 class='decreased'>-</h4>)
  </h4>
  </h4>

</template>

<script>
function addobject(obj) {
    return Object.values(obj).reduce((a,b) => a+b)
};
export default {
    name:'Skill',
    props: {
        stat:String,
        skill:Object,
        name:String,
        special:Object,
        luck:Object,
        specialname:String,
    },
    components: { Popup },
    data() {return {
        increased:false,
        decreased:false,
        showpopup:false,
        val:Object.values(this.skill).reduce((a,b) => a+b),
        skillname:this.stat,
    }},
    methods:{},
    emits:[],
    created() {},
    computed: {
      text() {
        return `${this.skillname}: ${this.value}`
      },
      valuesources() {
        let skill = JSON.parse(JSON.stringify(this.skill))
        skill[this.specialname] = addobject(this.special)*2
        skill.luck = Math.ceil(addobject(this.luck)/2) //Math.ceil((addobject(this.luck)-this.luck.base)/2)
        skill.constant = 2
        return skill
      },
      value() {
        let skill = JSON.parse(JSON.stringify(this.skill))
        skill[this.specialname] = addobject(this.special)*2
        skill.luck = Math.ceil(addobject(this.luck)/2) //Math.ceil((addobject(this.luck)-this.luck.base)/2)
        skill.constant = 2
        return addobject(skill)
      },
      valuenomods() {
        let skill = JSON.parse(JSON.stringify(this.skill))
        skill[this.specialname] = this.special.base*2
        skill.luck = Math.ceil(this.luck.base/2)
        skill.constant = 2 
        return addobject(skill)
      },
      // If the value when calculated WITH special AND skill mods - the value when calculated WITHOUT special AND skill mods > 0, then it's increased
      // Otherwise if the value WITH mods - the value WITHOUT mods > 0, it's decreased
      // Otherwise it's normal

      zincreased() {
        if (this.value - this.valuenomods > 0) {return true} else {return false}
      },
      zdecreased() {
        if (this.value - this.valuenomods < 0) {return true} else {return false}
      },
    },
    beforeUpdate() {},
    mounted() {} 
}
import Popup from '@/components/Popup.vue'
</script>

<style scoped>
h4 {
    margin:auto;
}
.main{
    width:100%;
}
.increased {
    display:inline;
    color: lightgreen;
}
.decreased {
    display:inline;
    color:red;
}
.txt {
  height:auto;
  margin-top:auto;
  margin-bottom:auto;
}
</style>
