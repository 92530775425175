<template>
<h1>Hello World</h1>
<button type='button' @click='addDescboxPerk'>Set Data</button>
<button type='button' @click='resetKestes'>Reset Kestes</button>
<button type='button' @click='addClaus'>Claus Kestes</button>
<button type='button' @click='addBtd'>BTD Kestes</button>
<button type='button' @click='resetKrato'>Reset Krato</button>
<button type='button' @click='addWeapon'>Add Weapon</button>
<CharacterSheet 
:key="charData.name" v-for="charData in charArray"
:charData='charData'
@modify-health='modifyHealth'
@modify-rads='modifyRads'
/>
</template>

<script>
import { db } from '@/db'
import { collection, doc, onSnapshot, query, limit, orderBy, getDocs , setDoc , getDoc } from 'firebase/firestore'




//let getData = async() => {
  //charData = await onSnapshot(doc(db, "colinDB", "Krato"))
  //charData = await db.collection("colinDB").doc("Krato").getDoc()
//} 
//getData();
import CharacterSheet from "@/components/CharacterSheet"

export default {
  name: 'App',
  components: {
    CharacterSheet
  },
  data() {
    return {
      name:"",
      charArray:[]
    }
  },
  methods: {
    async modifyHealth(name, missinghp) {
      console.log(`Character ${name} is having missing HP set to ${missinghp}`)
      let charref = doc(db, 'colinDB', name)
      missinghp = Number(missinghp)
      setDoc(charref, {missingHp: missinghp}, {merge: true})
    },
    async modifyRads(name, rads) {
      console.log(`Character ${name} is having Rads set to ${rads}`)
      let charref = doc(db, 'colinDB', name)
      rads = Number(rads)
      setDoc(charref, {rads: rads}, {merge: true})
      //Modify stats per RAD amount
      let endurance = {}
      let agility = {}
      let strength = {}
      if (-1<rads && rads<200)    {endurance.RadiationPoisoning = 0;  agility.RadiationPoisoning = 0;   strength.RadiationPoisoning = 0}
      if (199<rads && rads<400)   {endurance.RadiationPoisoning = -1; agility.RadiationPoisoning = 0;   strength.RadiationPoisoning = 0}
      if (399<rads && rads<600)   {endurance.RadiationPoisoning = -2; agility.RadiationPoisoning = -1;  strength.RadiationPoisoning = 0}
      if (599<rads && rads<800)   {endurance.RadiationPoisoning = -3; agility.RadiationPoisoning = -2;  strength.RadiationPoisoning = -1}
      if (799<rads && rads<1000)  {endurance.RadiationPoisoning = -3; agility.RadiationPoisoning = -2;  strength.RadiationPoisoning = -2}
      let zspecial = {endurance, agility, strength}
      console.log(rads, zspecial);
      setDoc(charref, {special: zspecial}, {merge:true})
    },
    addCheckboxPerk() {
      let name =          'Man Eater'
      let id =            'maneater'
      let label =         'Vs. Men'
      let popuptext =     '+2 extra damage and +1 to Speech checks on men.'
      let levelreq =      2
      let strength =      {[name]: 0}
      let perception =    {[name]: 0}
      let endurance =     {[name]: 0}
      let charisma =      {[name]: 0}
      let intelligence =  {[name]: 0}
      let agility =       {[name]: 0}
      let luck =          {[name]: 0}
      let perks =        {[name]: {checked:false}}
      
      let special={strength, perception, endurance, charisma, intelligence, agility, luck}

      let weaponstats = {
        guns:         {hit:{base:0},damage:{[name]:0},},
        energy:       {hit:{base:0},damage:{[name]:0},},
        explosives:   {hit:{base:0},damage:{[name]:0},},
        melee:        {hit:{base:0},damage:{[name]:0},},
        unarmed:      {hit:{base:0},damage:{[name]:0},},
      }

      let unchecked = {special,perks,weaponstats}

      strength =      {[name]: 0}
      perception =    {[name]: 0}
      endurance =     {[name]: 0}
      charisma =      {[name]: 0}
      intelligence =  {[name]: 0}
      agility =       {[name]: 0}
      luck =          {[name]: 0}
      perks =        {[name]: {checked:false}}

      special={strength, perception, endurance, charisma, intelligence, agility, luck}

      weaponstats = {
        guns:         {hit:{base:0},damage:{[name]:2},},
        energy:       {hit:{base:0},damage:{[name]:2},},
        explosives:   {hit:{base:0},damage:{[name]:2},},
        melee:        {hit:{base:0},damage:{[name]:2},},
        unarmed:      {hit:{base:0},damage:{[name]:2},},
      }

      let checked = {special,perks,weaponstats}

      let requirements = {
        charisma:7,
      }

      let checkbox = true
      let t = {unchecked, checked, name, id, label, popuptext, checkbox, requirements, levelreq}
      console.log(t)
      setDoc(doc(db, 'perks', id), t)
    },
    addDescboxPerk() {
      let name =          'Kill Shot'
      let id =            'killshot'
      let popuptext =     'You begin your attack announcing it is a "Kill Shot". Inflicting yourself with a -5 to hit but giving yourself +10 damage.'
      let levelreq =      6
      let requirements = {
        skills:{

          },
        special:{

        }
      }
      let descbox = true
      let line1 = 'Declare "Kill Shot"'
      let line2 = '-5 hit, +10 dmg'

      let t = { name, id, popuptext, requirements, levelreq, descbox, line1, line2 }
      console.log(t)
      setDoc(doc(db, 'perks', id), t)
    },
    addTrait() {
      let name =          'Four Eyes'
      let id =            'foureyes'
      let label =         'Glasses'
      let popuptext =     '+1 to Perception while wearing Glasses, -1 to Perception without Glasses.'
      let strength =      {[name]: 0}
      let perception =    {[name]: -1}
      let endurance =     {[name]: 0}
      let charisma =      {[name]: 0}
      let intelligence =  {[name]: 0}
      let agility =       {[name]: 0}
      let luck =          {[name]: 0}
      let traits =        {[name]: {checked:false}}
      
      let special={strength, perception, endurance, charisma, intelligence, agility, luck}
      let unchecked = {special,traits}

      strength =      {[name]: 0}
      perception =    {[name]: 1}
      endurance =     {[name]: 0}
      charisma =      {[name]: 0}
      intelligence =  {[name]: 0}
      agility =       {[name]: 0}
      luck =          {[name]: 0}
      traits =        {[name]: {checked:false}}

      special={strength, perception, endurance, charisma, intelligence, agility, luck}
      let checked = {special,traits}
      let checkbox = true
      let t = {unchecked, checked, name, id, label,popuptext, checkbox}
      console.log(t)
      setDoc(doc(db, 'traits', id), t)
    },
    resetKestes() {
      let missingHp =     5
      let level =         1
      let maxHp =         10
      let name =          'Kestes'
      let rads =          185
      let race =          'Human'
      let strength =      {base: 3}
      let perception =    {base: 6}
      let endurance =     {base: 5}
      let charisma =      {base: 7}
      let intelligence =  {base: 9}
      let agility =       {base: 2}
      let luck =          {base: 3}
      let special={strength, perception, endurance, charisma, intelligence, agility, luck}
      
      let barter =        {base:1}
      let energy =        {base:3}
      let explosives =    {base:4}
      let guns =          {base:2}
      let lockpick =      {base:1}
      let medicine =      {base:0}
      let melee =         {base:6}
      let repair =        {base:2}
      let science =       {base:1}
      let sneak =         {base:0}
      let speech =        {base:2}
      let survival =      {base:5}
      let unarmed =       {base:9}

      let skills={barter,energy,explosives,guns,lockpick,medicine,melee,repair,science,sneak,speech,survival,unarmed}

      
      let weaponstats = {
        guns:{hit:{base:0},damage:{base:0},},
        energy:{hit:{base:0},damage:{base:0},},
        explosives:{hit:{base:0},damage:{base:0},},
        melee:{hit:{base:0},damage:{base:0},},
        unarmed:{hit:{base:0},damage:{base:0},},
      }

      let perks = {
        'Lady Killer': {
          checked:true,
          id:'ladykiller',
          name:'Lady Killer',
        },
        'Hunter': {
          id:'hunter',
          name:'Hunter',
        }
      }
      let equippedweapon = {
        id:'sniperrifle',
        name:'Sniper Rifle',
      }
      let t = {name, missingHp, level, maxHp, rads, special, skills, weaponstats, perks, equippedweapon, race}
      console.log(t)
      setDoc(doc(db, 'colinDB', name), t)
    },
    addClaus() {
      let traits = {
        Claustrophobia: {
          checked: false,
          id: 'claustrophobia',
          name: 'Claustrophobia'
        },
        'Four Eyes': {
          checked: true,
          id: 'foureyes',
          name: 'Four Eyes'
        }
      }
      setDoc(doc(db, 'colinDB', 'Kestes'), {traits:traits}, {merge:true})
    },
    addBtd() {
      let traits = {
        'Built to Destroy': {
          Descbox: true,
          id: 'builttodestroy',
          name: 'Built to Destroy'
        },

      }
      setDoc(doc(db, 'colinDB', 'Kestes'), {traits:traits}, {merge:true})
    },
    resetKrato() {
      let missingHp =     5
      let level =         2
      let maxHp =         16
      let name =          'Krato'
      let rads =          10
      let race =          'Ghoul'
      let strength =      {base: 6}
      let perception =    {base: 4}
      let endurance =     {base: 8}
      let charisma =      {base: 2, Ghoul: -1}
      let intelligence =  {base: 3}
      let agility =       {base: 9}
      let luck =          {base: 9}
      let special={strength, perception, endurance, charisma, intelligence, agility, luck}
      
      let barter =        {base:1}
      let energy =        {base:3}
      let explosives =    {base:4}
      let guns =          {base:2}
      let lockpick =      {base:1}
      let medicine =      {base:0}
      let melee =         {base:6}
      let repair =        {base:2}
      let science =       {base:1}
      let sneak =         {base:0}
      let speech =        {base:2}
      let survival =      {base:5}
      let unarmed =       {base:9}

      let skills={barter,energy,explosives,guns,lockpick,medicine,melee,repair,science,sneak,speech,survival,unarmed}

      let traits = {
        Claustrophobia: {
          checked: false,
          id: 'claustrophobia',
          name: 'Claustrophobia'
        },
        'Four Eyes': {
          checked: true,
          id: 'foureyes',
          name: 'Four Eyes'
        }
      }


      let weaponstats = {
        guns:{hit:{base:0},damage:{base:0},},
        energy:{hit:{base:0},damage:{base:0},},
        explosives:{hit:{base:0},damage:{base:0},},
        melee:{hit:{base:0},damage:{base:0},},
        unarmed:{hit:{base:0},damage:{base:0},},
      }

      let equippedweapon = {
        id:'sniperrifle',
        name:'Sniper Rifle',
      }
      let t = {name, missingHp, level, maxHp, rads, special, skills, traits, weaponstats, equippedweapon, race}
      console.log(t)
      setDoc(doc(db, 'colinDB', name), t)
    },
    addWeapon() {
      let name = 'Hatchet'
      let id = 'hatchet'
      let damage = '1d6'
      let skillReqPoints = 25
      let skillReq = 'Melee'
      let strengthReq = 2
      let range = 'thrown'
      let hands = 1
      let specialReq = 'strength'
      let damagetype = ''
      let weaponclass = 'melee'
      let stats = {name, id, damage, skillReqPoints, skillReq, strengthReq, range, hands, specialReq, damagetype, weaponclass}

      setDoc(doc(db, 'weapons', id), stats)
    }
  },
  beforeCreate() {
    document.querySelector('body').style.backgroundColor = 'lightslategray' ;
  },
  async created() {
    const q = query(collection(db, "colinDB"), orderBy("name"), limit(10));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chars = [];
      querySnapshot.forEach((doc) => {
        chars.push(doc.data())
      });
        this.charArray = chars
      });

  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

</style>
