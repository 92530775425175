<template>
  <div>
    <h4>
    <Popup :text='trait.name' :popuptext='trait.popuptext' /></h4>
    <Checkbox v-if='trait.checkbox === true' :label='trait.label' :char='obj' @toggle-checked="toggleChecked" />
    <Descbox v-if='trait.descbox === true' :line1='trait.line1' :line2='trait.line2' />
  </div>
</template>

<script>
export default {
    name:'Traits',
    components: {Checkbox, Descbox, Popup},
    //OBJ = COMPLETE TRAIT OBJECT FROM CHAR DB
    props: {
        obj:Object,
        name:String,

    },
    data() {
        return{
            trait : {}
        }
    },
    methods: {
        toggleChecked() {
            console.log('Toggle triggered')
            console.log(this.trait)
           if (this.obj.checked === true){
                setDoc(doc(db, 'colinDB', this.name), this.trait.checked, {merge:true})
            } else {
                setDoc(doc(db, 'colinDB', this.name), this.trait.unchecked, {merge:true})
            } 


        },
    },
    async created() {
        let docdata = await getDoc(doc(db, 'traits', this.obj.id))
        console.log('printing data')
        console.log(docdata.data())
        this.trait = docdata.data()

    },
}
import Checkbox from '@/components/Checkbox.vue'
import Descbox from '@/components/Descbox.vue'
import Popup from '@/components/Popup.vue'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '@/db'
</script>

<style>

</style>